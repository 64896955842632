import PropTypes from "prop-types"
import React, { useState } from "react"
import axios from "axios"
import { useNavigate } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/Logo.svg"
import { base_url, router } from "utils/APIUrls"

const Login = props => {
  document.title = "Login || Stelo - Steel Trading Dashboard"

  const [emailOrPhone, setEmailOrMobile] = useState("")
  const navigate = useNavigate()
  var SignupCall = e => {
    e.preventDefault()

    const reqObj = {
      email: emailOrPhone,
    }
    axios
      .post(base_url + router.otp_send, reqObj)
      .then(res => {
        toast.success(res.data.msg || "OTP Sent Successfully")
        localStorage.setItem("user", emailOrPhone)
        navigate("/forgot-password")
      })
      .catch(err => {
        if (err?.response?.data?.errors) {
          err?.response?.data?.errors.forEach(error => {
            console.log(error.msg)

            toast.error(errors.msg)
          })
        } else {
          toast.error(err?.response?.data?.message || "An error occured")
        }
      })
  }
  
  return (
    <React.Fragment>
      <ToastContainer />
      <div
        className="account-pages my-5 pt-sm-5 d-flex align-items-center"
        style={{ minHeight: "85vh" }}
      >
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary-subtle">
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h4 className="text-primary">Sign In</h4>
                        <p>Sign in to continue to Stelo.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Form className="form-horizontal" onSubmit={SignupCall}>
                      <div className="mb-3">
                        <Label className="form-label">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          value={emailOrPhone}
                          onChange={e => setEmailOrMobile(e.target.value)}
                        />
                      </div>

                      <div className="mt-3 d-grid">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
