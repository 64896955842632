export const socket_url = `http://localhost:9090`

// export const base_url = "http://localhost:9090/api/admin"
// export const orderImage_url = `http://localhost:9090/OrderImage/`
// export const materialDoc_url = `http://localhost:9090/materialDocument/`
// export const queryImg = `http://localhost:9090/QueryImage/`

export const base_url = "http://143.110.253.43:9090/api/admin"
export const materialDoc_url = `http://143.110.253.43:9090/materialDocument/`
export const orderImage_url = `http://143.110.253.43:9090/OrderImage/`
export const queryImg = `http://143.110.253.43:9090/QueryImage/`
   
// export const socket_url = `http://143.110.253.43:9090`

export var router = {
  //sign-in
  otp_send: "/otp-send-admin",
  otp_verify: "/otp-verify-admin",

  //user
  get_user: "/get-user",
  block_user: "/block-user",
  unblock_user: "/unblock-user",

  //category
  add_category: "/add-category",
  edit_category: "/update-category",
  get_category: "/get-categories",
  get_categoryById: "/get-categoriesById",
  delete_category: "/delete-category",

  //gst
  approve_gst_status: "/approve-gst-status",
  disapprove_gst_status: "/disapprove-gst-status",

  //Order
  get_retail_order_details: "/get-retail-order-details-admin",
  get_bulk_order_details: "/get-bulk-order-details-admin",
  edit_img_order: "/edit-img-order",
  edit_item_data: "/edit-item-data",
  get_items_details: "/get-items-details-admin",
  reject_order: "/reject-order",
  accept_order: "/accept-order",
  processing_order: "/processing-order",
  ready_to_ship_order: "/ready-to-ship-order",
  shipping_order: "/shipping-order",
  delivered_order: "/delivered-order",
  ready_to_pickup_order: "/ready-to-pickup-order",
  pickup_done_order: "/pickup-done-order",
  get_order_status: "/get-order-status",

  //materials
  add_material: "/add-material",
  edit_material: "/edit-material",
  sort_material: "/sort-material",
  get_materials: "/get-materials",
  delete_materials: "/delete-materials",

  //notifications
  store_notification: "/store-notification-admin",
  order_status_notification: "/order-status-notification",

  // Query
  get_retail_query: "/get-retail-query",
  get_bulk_query: "/get-bulk-query",
  get_query_detail: "/get-query-detail-admin",
  edit_query: "/edit-query-admin",
  edit_queryItem: "/edit-query-item",

  //chat Message
  add_message : "/add-message",
  get_message : "/get-message",

}
